import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Segment, { Container, Row } from 'components/layout';
import Image from 'components/image';
import Markdown from 'components/markdown';

import s from './Matrix.scss';

export default class Matrix extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    caption: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    text2: PropTypes.string,
    columnImage: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }

  static defaultProps = {
    text: null,
    image: null,
    text2: null,
  }

  render() {
    const { heading, text, image, columnImage, caption, text2 } = this.props;

    return (
      <section className={s.matrix}>
        <Segment>
          <Container>
            <Row>
              <div className={s.matrix__content}>
                <h1 className={s.matrix__heading}>{heading}</h1>
                <Markdown cols={2} source={text} className={s.matrix__text} />
                {image &&
                  <div className={s.matrix__contentImage}>
                    <Image
                      src={image.src}
                      alt={image.alt || ''}
                      width={image.width}
                      height={image.height}
                      transition="scale"
                    />
                  </div>
                }
                <Markdown cols={2} source={text2} className={s.matrix__text} />
              </div>

              <figure className={s.matrix__columnImageWrap}>
                <div className={s.matrix__columnImage}>
                  <Image
                    src={columnImage.src}
                    alt={columnImage.alt || ''}
                    width={columnImage.width}
                    height={columnImage.height}
                    type="wipe"
                  />
                </div>
                <figcaption className={s.matrix__caption}>{caption}</figcaption>
              </figure>
            </Row>
          </Container>
        </Segment>
      </section>
    );
  }
}
