import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Container, Row } from 'components/layout';

import Button from 'components/button';
import Clickable from 'components/clickable';

import s from './Join.scss';

export default class Join extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    ctas: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        ctaText: PropTypes.string,
        ctaUrl: PropTypes.string,
      }),
    ),
    mission: PropTypes.string,
    by: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
    text: '',
    ctas: [],
    links: [],
    mission: '',
    by: '',
  }

  render() {
    const { heading, text, ctas, links, mission, by } = this.props;

    return (
      <section className={s.join}>
        <div className={s.join__inner}>
          <div className={s.join__intro}>
            <Container>
              <Row>

                <div className={s.join__content}>
                  <h1 className={s.join__heading}>{heading}</h1>
                </div>

                <div className={s.join__content}>
                  <p className={s.join__text}>{text}</p>

                  <div className={s.join__cta}>
                    {ctas.map((cta, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className={s.join__button} key={`join-button-${i}`}>
                        <Button key={cta.text} to={cta.url} color="transparent">
                          {cta.text}
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>

              </Row>
            </Container>
          </div>

          <div className={s.join__links}>
            <Container>
              <Row>
                {links.map(link => (
                  <div className={s.join__linkItem} key={link.heading}>
                    <h2 className={s.join__linkHeading}>{link.heading}</h2>
                    <Clickable key={link.ctaText} to={link.ctaUrl}>
                      {link.ctaText}
                    </Clickable>
                  </div>
                ))}
              </Row>
            </Container>
          </div>

          <Container>
            <div className={s.join__outro}>
              <p className={s.join__mission}>
                {mission.split('\n').map((line, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={`mission-${index}`}>
                    {index !== 0 && (<br />)}
                    {line}
                  </Fragment>
                ))}
              </p>
              <p className={s.join__by}>{by}</p>
            </div>
          </Container>
        </div>
      </section>
    );
  }
}
