import { graphql } from 'gatsby';
import Vision from 'routes/vision/Vision';

export default Vision;

export const query = graphql`
  query Vision {
    page: contentfulPageVision {
      pageTitle
      metaDescription
      heroHeading: heroTitle
      heroImage {
        ...image
      }
      heroVideoFile {
        file {
          url
        }
      }
      heroVideoBg {
        file {
          url
        }
      }
      personalizeHeading: personalizeTitle
      personalizeText: personalizeBody {
        text: personalizeBody
      }
      personalizeImage {
        ...image
      }
      oneWorld: module {
        ...moduleList
      }
      matrix {
        ...moduleList
      }
      matrixText: matrixBody {
        text: matrixBody
      }
      matrixImage {
        ...image
      }
      matrixCaption
      matrixText2: matrixBody2 {
        text: matrixBody2
      }
      journeyHeading: journeyTitle
      journeyText: journeyBody {
        text: journeyBody
      }
      journeyBackgroundImage {
        ...image
      }
      journeyImage {
        ...image
      }
      journeyText2: journeyBody2 {
        text: journeyBody2
      }
      journeyOutro
      principles: principlesModule {
        ...moduleList
      }
      joinHeading: joinTitle
      joinText: joinBody {
        text: joinBody
      }
      joinCtaText
      joinCtaUrl
      joinCtaText2
      joinCtaUrl2
      joinModules {
        ...submoduleList
      }
      joinMissionStatement {
        joinMissionStatement
      }
      joinBy
      cta {
        ...moduleList
      }
    }
  }
`;
