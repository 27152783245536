import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import extractMeta from 'utils/extract-meta';
import { withUIContext } from 'context/ui';

import Modules from 'containers/modules';

import DarkBackground from 'components/dark-background';
import Hero from 'components/hero';

import Personalize from './components/personalize';
import OneWorld from './components/one-world';
import Matrix from './components/matrix';
import Journey from './components/journey';
import Join from './components/join';

import SEO from 'components/seo';

class Vision extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    ui: PropTypes.object,
  }

  componentDidMount() {
    this.props.ui.setNavTheme('light');
  }

  componentWillUnmount() {
    this.props.ui.setNavTheme('dark');
  }

  render() {
    const { page } = this.props.data;
    const { pathname } = this.props.location;

    return (
      <Fragment>
        <SEO pathname={pathname} {...extractMeta(page)} article />
        <Hero
          heading={page.heroHeading}
          theme='light'
          videoFile={_get(page, 'heroVideoFile.file.url')}
          videoBg={_get(page, 'heroVideoBg.file.url')}
          poster={_get(page.heroImage, 'file.url')}
          darkVideo
        />

        <LazyLoad once offset={500}>
          <Personalize
            heading={page.personalizeHeading}
            text={_get(page.personalizeText, 'text')}
            image={{
              src: _get(page.personalizeImage, 'file.url'),
              alt: _get(page.personalizeImage, 'description'),
              width: _get(page.personalizeImage, 'file.details.image.width'),
              height: _get(page.personalizeImage, 'file.details.image.height'),
            }}
          />
        </LazyLoad>

        <DarkBackground>
          <LazyLoad once offset={500}>
            <OneWorld
              heading={_get(page.oneWorld, 'heading')}
              text={_get(page.oneWorld, 'text.text')}
              image={{
                src: _get(page.oneWorld, 'image.file.url'),
                alt: _get(page.oneWorld, 'description'),
                width: _get(page.oneWorld, 'image.file.details.image.width'),
                height: _get(page.oneWorld, 'image.file.details.image.height'),
              }}
              reversed={_get(page.oneWorld, 'isRowReversed')}
              alignTop={_get(page.oneWorld, 'verticalAlignment')}
              ctaText={_get(page.oneWorld, 'ctaText')}
              ctaUrl={_get(page.oneWorld, 'ctaUrl')}
              // Included all the possible props, to prevent confusion & allow flexibility
              // but feel free to delete unused ones, or ones we don't want them to change.
            />
          </LazyLoad>
          <LazyLoad once offset={500}>
            <Matrix
              heading={_get(page.matrix, 'heading')}
              caption={page.matrixCaption}
              text={_get(page.matrixText, 'text')}
              image={{
                src: _get(page.matrixImage, 'file.url'),
                alt: _get(page.matrixImage, 'description'),
                width: _get(page.matrixImage, 'file.details.image.width'),
                height: _get(page.matrixImage, 'file.details.image.height'),
              }}
              columnImage={{
                src: _get(page.matrix, 'image.file.url'),
                alt: _get(page.matrix, 'description'),
                width: _get(page.matrix, 'image.file.details.image.width'),
                height: _get(page.matrix, 'image.file.details.image.height'),
              }}
              text2={_get(page.matrixText2, 'text')}
            />
          </LazyLoad>

          <LazyLoad once offset={500}>
            <Journey
              heading={page.journeyHeading}
              text={_get(page.journeyText, 'text')}
              backgroundImage={_get(page.journeyBackgroundImage, 'file.url')}
              image={{
                src: _get(page.journeyImage, 'file.url'),
                alt: _get(page.journeyImage, 'description'),
                width: _get(page.journeyImage, 'file.details.image.width'),
                height: _get(page.journeyImage, 'file.details.image.height'),
              }}
              text2={_get(page.journeyText2, 'text')}
              outro={page.journeyOutro}
            />
          </LazyLoad>

          <LazyLoad once offset={500}>
            <Modules list={[page.principles]} />
          </LazyLoad>

          <Join
            heading={page.joinHeading}
            text={_get(page.joinText, 'text')}
            ctas={[
              {
                text: page.joinCtaText,
                url: page.joinCtaUrl,
              },
              {
                text: page.joinCtaText2,
                url: page.joinCtaUrl2,
              },
            ]}
            links={page.joinModules.map(link => ({
              heading: link.heading,
              ctaText: link.ctaText,
              ctaUrl: link.ctaUrl,
            }))}
            mission={_get(page.joinMissionStatement, 'joinMissionStatement')}
            by={page.joinBy}
          />
        </DarkBackground>
        <Modules list={[page.cta]} />
      </Fragment>
    );
  }
}

export default withUIContext(Vision);
