import React, { PureComponent, Children } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import { Container, Row } from 'components/layout';
import Image from 'components/image';

import s from './OneWorld.scss';

export default class OneWorld extends PureComponent {

  static propTypes = {
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
    reversed: PropTypes.bool,
    alignTop: PropTypes.bool,
    heading: PropTypes.string,
    text: PropTypes.string,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    children: PropTypes.node,
  }

  static defaultProps = {
    alignTop: false,
    heading: null,
    text: null,
    ctaUrl: null,
    children: null,
  }

  render() {
    const {
      image,
      reversed,
      alignTop,
      heading,
      text,
      ctaText,
      ctaUrl,
      children,
    } = this.props;

    const childArray = Children.toArray(children);

    return (
      <section className={s('oneWorld', { [s.reversed]: reversed, [s.vCenter]: !alignTop })}>
        <Container>
          <Row reversed={reversed}>
            <div className={s.oneWorld__figure}>
              <div className={s.oneWorld__image}>
                <Image
                  src={image.src}
                  alt={image.alt || ''}
                  width={image.width}
                  height={image.height}
                  transition="wipe"
                />
              </div>
            </div>

            <div className={s.oneWorld__content}>

              <div className={s.oneWorld__contentInner}>
                <h1 className={s.oneWorld__heading}>{heading}</h1>
                {text && <p className={s.oneWorld__text}>{text}</p>}
                {childArray.map((child, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`oneWorld__child-${i}`} className={s.oneWorld__child}>
                    {child}
                  </div>
                ))}
              </div>
            </div>
            { ctaUrl && <Button to={ctaUrl} color="transparent">{ctaText}</Button> }
          </Row>
        </Container>
      </section>
    );
  }
}
