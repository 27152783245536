import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import s from './DarkBackground.scss';

export default class DarkBackground extends PureComponent {

  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: undefined,
  }

  render() {
    return (
      <div className={s.darkBackground}>
        {this.props.children}
      </div>
    );
  }
}
