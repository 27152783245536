import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { TimelineLite } from 'gsap';

import { Container, Row } from 'components/layout';
import Image from 'components/image';
import Markdown from 'components/markdown';
import ViewportEnter from 'components/viewport-enter';

import s from './Journey.scss';

export default class Journey extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    backgroundImage: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    text2: PropTypes.string,
    outro: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
    text: null,
    backgroundImage: null,
    image: null,
    text2: null,
    outro: null,
  }

  animate = () => {
    const t = new TimelineLite();

    t.to(this.background, 1, { autoAlpha: 1 });
  }

  render() {
    const { heading, text, backgroundImage, image, text2, outro } = this.props;

    return (
      <ViewportEnter onEnter={this.animate} threshold={0.2}>
        <section className={s.journey}>
          <div className={s.journey__background} ref={(el) => { this.background = el; }} style={{ backgroundImage: `url(${backgroundImage})` }} />
          <div className={s.journey__intro}>
            <Container>
              <Row>
                <div className={s.journey__content}>
                  <h1 className={s.journey__heading}>{heading}</h1>
                  <Markdown cols={2} className={s.journey__text} source={text} />
                </div>
              </Row>
            </Container>
          </div>
          <Container>

            {image &&
              <div className={s.journey__imageWrap}>
                <Image
                  src={image.src}
                  alt={image.alt || ''}
                  width={image.width}
                  height={image.height}
                  transition="scale"
                />
              </div>
            }

            <Row>
              <div className={s.journey__content}>
                <div className={s.journey__text}>
                  <Markdown cols={2} source={text2} />
                </div>
              </div>
              <div className={s.journey__outroWrap}>
                <p className={s.journey__outro}>{outro}</p>
              </div>
            </Row>
          </Container>
        </section>
      </ViewportEnter>
    );
  }
}
