import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';

import { Container, Row } from 'components/layout';
import Image from 'components/image';
import ViewportEnter from 'components/viewport-enter';

import s from './Personalize.scss';

export default class Personalize extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }

  static defaultProps = {
    heading: '',
    text: '',
    image: null,
  }

  animate = () => {
    const t = new TimelineLite();

    t.addLabel('start');

    t.staggerFromTo([this.heading, this.content], 1, { y: 40, autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, 0.2, 'start');
    if (this.props.image) {
      t.fromTo(this.image, 0.75, { autoAlpha: 0 }, { autoAlpha: 1 }, 'start+=0.5');
    }
  }

  render() {
    const { heading, text, image } = this.props;

    return (
      <ViewportEnter onEnter={this.animate}>
        <section className={s.personalize}>
          <Container>
            <Row>
              <div className={s.personalize__headingWrap} ref={(el) => { this.heading = el; }}>
                <h1 className={s.personalize__heading}>{heading}</h1>
              </div>
              <div className={s.personalize__content} ref={(el) => { this.content = el; }}>
                <p className={s.personalize__text}>{text}</p>
              </div>
            </Row>
          </Container>
          {image && (
            <div className={s.personalize__imageWrap} ref={(el) => { this.image = el; }}>
              <div className={s.personalize__image}>
                <Image
                  src={image.src}
                  alt={image.alt || ''}
                  width={image.width}
                  height={image.height}
                />
              </div>
            </div>
            )}
        </section>
      </ViewportEnter>
    );
  }
}
